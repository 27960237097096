import { GlobeAsiaAustraliaIcon, ArrowPathIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs';
import { useState } from 'react';
import { getLocationSearch, postTripPlan } from '../services/api';
import { TripPlan } from '../interfaces/interfaces';
import { validate, StructError } from 'superstruct'
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import AutoComplete from '../components/autoComplete';

interface SearchResult {
    id: number
    country: string
    city: string
    combined: string
}

export default function SearchDestinationPure() {
    const [currentSuggestions, setCurrentSuggestions] = useState<string[]>([])
    const [locations, setLocations] = useState<SearchResult[]>([])
    const [formSending, setFormSending] = useState(false)
    //const errors = useActionData() as any;
    //const navigation = useNavigation();
    const dateFrom = dayjs().format('YYYY-MM-DD')
    const dateTo = dayjs().add(1, 'day').format('YYYY-MM-DD')
    const [formData, setFormData] = useState({
        destination: {
            city: "",
            country: "",
        },
        date_from: "date",
        date_to: ""
    }); 
    
    async function submitForm(event: React.FormEvent) {
        event.preventDefault()
        setFormSending(true)
        const formData = new FormData(event.target as HTMLFormElement);
        let entries = Object.fromEntries(formData); 
        // Override destination from a string to the formdata's object
        let errors: any = {};   
        //console.log(formData)
        //console.log(entries)
        const cloneEntries = Object.assign({}, entries);

        delete cloneEntries.city
        delete cloneEntries.country
        const destination = {
            destination: {
                city: entries.city,
                country: entries.country
            }
        }
        Object.assign(cloneEntries, destination);

        const [err, requestData] = validate(cloneEntries, TripPlan)
        console.log(err)
        console.log(requestData)
        if (err instanceof StructError || requestData === undefined) {
            errors.message = 'Unable to take off. Please try again'
            setFormSending(false)
            return errors
        }
        
        const response = await postTripPlan(requestData)
        const data = await response.json()
        console.log(data)
        
        if(response.ok) {
            window.location.href = `/trip-planner/${data.id}/trip-profile`
            return
        }
        setFormSending(false)
        return errors
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const suggestionSelected = (suggestion: string) => {
        // Save to form
        console.log(suggestion)
        const selected = locations.filter((location) => suggestion === location.combined)[0]
        console.log(selected)
        const destination = {
            city: selected.city,
            country: selected.country
        }
        setFormData((prevFormData) => ({ ...prevFormData, ['destination']: destination }));

    }

    async function suggestionChanged(value: string) {
        //const {name, value } = event.target
        // Look up string in existing response
        // Make new request to api
        // Return and save as state
        const results: SearchResult[] = await getLocationSearch(value)
        let suggestions: string[] = []
        if (results.length >= 1) {
            suggestions = results.map((result) => {
                return result.combined
            })
        }
        setLocations(results)
        setCurrentSuggestions(suggestions)
    }
    
    return (
        <form method="POST" onSubmit={submitForm}>
            <input name="city" type='hidden' value={formData.destination.city} />
            <input name="country" type='hidden' value={formData.destination.country} />
            <div className="md:rounded-full md:flex mx-4 md:mx-auto">
                <div className="relative bg-transparent border-transparent md:inline-block grow">
                    <label className='flex items-center mb-2'>
                        <span className="pointer-events-none bg-transparent border-transparent z-10">
                            <GlobeAsiaAustraliaIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                        <span className='ml-2 font-medium'>
                            Destination
                        </span>
                    </label>
                    <div className='ml-0 md:ml-7'>
                        <AutoComplete 
                            name='destination'
                            suggestions={currentSuggestions} 
                            onChangeHandler={suggestionChanged}
                            onSelectHandler={suggestionSelected} />
                    </div>
                </div>

                <div className='mt-4 md:mt-0 md:inline-block'>
                    <div className='flex justify-between md:flex-none md:justify-normal md:inline-block md:h-full'>
                        <div className='relative inline-block h-full w-5/12 md:w-auto'>
                            <label className='flex items-center mb-2'>
                                <span className="pointer-events-none bg-transparent border-transparent z-10">
                                    <CalendarDaysIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                                <span className='ml-2 font-medium'>
                                    Date From
                                </span>
                            </label>
                            <div className='ml-0 md:ml-7'>
                                <input
                                    type="date"
                                    min={dateFrom}
                                    name="date_from"
                                    id="date_from"
                                    // classname='click-anywhere focus:outline-none focus:border-transparent focus:border-b-1 focus:border-b-gray-300 border-b-1 border-b-gray-300 rounded-full md:rounded-none md:bg-transparent focus:ring-0 border-transparent text-gray-900 placeholder:text-gray-400 text-sm min-w-full'
                                    className="click-anywhere border-x-transparent border-t-transparent border-coolGray-200 focus:outline-none focus:border-transparent focus:border-b-1 focus:border-b-gray-300 rounded-none bg-transparent text-gray-400 placeholder:text-gray-400 w-full md:w-36 z-0 pl-0 text-md"
                                    value={formData.date_from}
                                    onChange={handleChange}
                                    placeholder='Date From'
                                    //onFocus={(e) => {e.target.type = 'date'}}
                                />
                            </div>
                        </div>
                        
                        <div className='relative inline-block h-full w-5/12 md:w-auto'>
                            <label className='flex items-center mb-2'>
                                <span className="pointer-events-none bg-transparent border-transparent z-10">
                                    <CalendarDaysIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                                <span className='ml-2 font-medium'>
                                    Date To
                                </span>
                            </label>
                            <div className='ml-0 md:ml-7'>
                                <input
                                    type="date"
                                    min={dateTo}
                                    name="date_to"
                                    id="date_to"
                                    className="click-anywhere border-x-transparent border-t-transparent border-coolGray-200 focus:outline-none focus:border-transparent focus:ring-0 rounded-none md:bg-transparent inline-block text-gray-400 placeholder:text-gray-400 leading-6 flex-none md:w-36 w-full h-full z-0 pl-0 text-md"
                                    value={formData.date_to}
                                    onChange={handleChange}
                                    //defaultValue={dateTo}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='inline-block h-full text-sm align-top w-full md:w-auto'>
                        <button 
                            className='rounded-full px-8 py-4 text-white text-sm font-medium leading-normal bg-explraBtnRed-primary hover:bg-explraBtnRed-secondary transition duration-200
                            md:rounded-lg block md:inline-block w-full md:w-auto mt-4 md:mt-0 md:min-h-full'
                            typeof='Submit'>{formSending ? <ArrowPathIcon className='h-6 w-6 text-white animate-spin mx-auto' /> : 'GO!'}</button>
                    </div>
                    
                    
                        
                </div>
            </div>
            
        </form>
    )
}